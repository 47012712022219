

















































































































import {Component, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import {Contract} from '@/model/resource/Contract'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {InputDate, InputSelect} from '@simpli/vue-input'
import DappDetailedStatsChart from '@/components/dappDetailed/DappDetailedStatsChart.vue'
import DappDetailedChartFilter from '@/components/dappDetailed/DappDetailedChartFilter.vue'
import {DappDetailedGraphPeriod} from '@/enums/DappDetailedGraphPeriod'
import {CacheAssist} from '@/app/CacheAssist'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {
    DappDetailedStatsChart,
    DappDetailedChartFilter,
    PercentageProgress,
    InputSelect,
    InputDate,
  },
})
export default class DappDetailedStats extends MixinScreenSize {
  @Prop({type: String, default: null}) initialHash!: string | null
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Boolean}) readOnly?: boolean

  selectedContract: Contract | null = null
  period: DappDetailedGraphPeriod = DappDetailedGraphPeriod.SEVEN_DAYS

  cache = new CacheAssist(
    this.period,
    this.dapp.idDappPk,
    this.selectedContract?.hash
  )

  get displayGraph() {
    // Todo: It's a temporary solution, will be removed when we discover how to get contracts from NeoX
    return this.selectedContract?.hash !== 'dummyX'
  }

  get contractOptions() {
    if (!this.dapp.contract) return []

    const contractOptions: Contract[] = this.dapp.contract

    const hasOnlyOneContract = contractOptions.length === 1
    if (hasOnlyOneContract) {
      this.setSelectedContract(contractOptions[0])
    }

    const hasManyContracts = contractOptions.length > 1
    if (hasManyContracts) {
      const legendContract = new Contract()

      legendContract.title = this.$tc(
        'components.dappDetailsCard.nContracts',
        contractOptions.length,
        {qty: contractOptions.length}
      )

      contractOptions.push(legendContract)
    }

    return contractOptions.reverse()
  }

  mounted() {
    this.populateResources()
  }

  populateResources() {
    if (!this.initialHash || !this.dapp.contract) {
      this.populateChart()
      return
    }

    const initialContract = this.dapp.contract.find(
      contract => contract.hash === this.initialHash
    )

    this.setSelectedContract(initialContract ?? null)
  }

  copyToClipboard() {
    if (!this.selectedContract?.hash) return

    this.$utils.copyToClipboard(this.selectedContract.hash)
    this.$toast.info('system.info.copiedToClipboard')
  }

  onFilterChart(period: DappDetailedGraphPeriod) {
    this.setPeriod(period)
  }

  setSelectedContract(contract: Contract | null) {
    this.selectedContract = contract

    if (this.cache.hash === contract?.hash) return

    this.cache = new CacheAssist(
      this.period,
      this.dapp.idDappPk,
      this.selectedContract?.hash
    )

    this.populateChart()
  }

  setPeriod(period: DappDetailedGraphPeriod) {
    this.period = period

    if (this.cache.period === period) return

    this.cache = new CacheAssist(
      this.period,
      this.dapp.idDappPk,
      this.selectedContract?.hash
    )

    this.populateChart()
  }

  populateChart() {
    this.$await.run(`getDataChart_${this.dapp.$id}`, async () => {
      await this.cache.populate()
    })
  }
}
