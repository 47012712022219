import { render, staticRenderFns } from "./DappDetailed.vue?vue&type=template&id=608fb3bf&scoped=true"
import script from "./DappDetailed.vue?vue&type=script&lang=ts"
export * from "./DappDetailed.vue?vue&type=script&lang=ts"
import style0 from "./DappDetailed.vue?vue&type=style&index=0&id=608fb3bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608fb3bf",
  null
  
)

export default component.exports