


















































import {Component, Prop} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import TokensPriceTable from '@/components/tokensprice/TokensPriceTable.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'

@Component({
  components: {PoweredBy, LastPriceUpdated, TokensPriceTable},
})
export default class DappDetailedTokensTable extends MixinScreenSize {
  @Prop({type: Dapp, required: true}) dapp!: Dapp
  @Prop({type: Object, required: true})
  tokensCollection!: TokensTableItemsCollection

  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
  ]

  lastTimeUpdated = ''

  handleChangeLastTimeUpdated(lastTimeUpdated: string) {
    this.lastTimeUpdated = lastTimeUpdated
  }
}
