










































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import {NeoFeed} from '@/app/NeoFeed'
import {Dapp} from '@/model/resource/Dapp'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import DappDetailedStats from '@/components/dappDetailed/DappDetailedStats.vue'
import DappDetailedFeed from '@/components/dappDetailed/DappDetailedFeed.vue'
import DappDetailedTokensTable from '@/components/dappDetailed/DappDetailedTokensTable.vue'
import DappDetailedSummary from '@/components/dappDetailed/DappDetailedSummary.vue'
import {DappInfo} from '@/model/resource/DappInfo'

@Component({
  components: {
    DappDetailedStats,
    DappDetailedFeed,
    DappDetailedTokensTable,
    DappDetailedSummary,
    NavbarMenu,
    AppFooter,
  },
})
export default class DappDetailed extends Vue {
  @Prop({type: Boolean, required: true}) isDapp!: boolean

  tokensTableItemsCollection = new TokensTableItemsCollection()

  initialHash = this.$route.params.hash ?? null

  feed = new NeoFeed()
  dappInfo = new DappInfo()
  dapp = new Dapp()

  slugOrId?: string | number

  searchBySlug = false
  alreadyRequested = false

  get hasTokens() {
    return (
      this.tokensTableItemsCollection.items.length > 0 &&
      this.tokensTableItemsCollection.items[0].$id !== 0
    )
  }

  async created() {
    this.applyResources()
    await this.populateResources()
  }

  applyResources() {
    this.tokensTableItemsCollection.withoutTotal = true
    this.tokensTableItemsCollection.perPage = this.tokensTableItemsCollection.total
    this.tokensTableItemsCollection.orderBy = 'marketCap'
    this.tokensTableItemsCollection.minOrder = 0
    this.tokensTableItemsCollection.asc = false
  }

  async populateResources() {
    if (!this.$route.params.id) {
      await this.$router.push('/')
      return
    }

    const dappId = Number(this.$route.params.id)
    const slugOrId = !isNaN(dappId) ? dappId : this.$route.params.id
    this.slugOrId = slugOrId

    if (isNaN(dappId)) {
      this.searchBySlug = true
      this.dapp.slug = String(slugOrId)
      this.tokensTableItemsCollection.slugDapp = String(slugOrId)
    } else {
      this.dapp.$id = Number(slugOrId)
    }

    this.$await.init(`getInfo_${slugOrId}`)

    this.feed.populate().catch(() => {
      this.feed = new NeoFeed()
    })

    try {
      const promises: Array<Promise<any>> = [
        this.dapp.getDapp(slugOrId),
        this.tokensTableItemsCollection.listTokensTableItems(),
      ]

      await Promise.all(promises)

      const newPromises: Array<Promise<any>> = [
        this.dappInfo.getInfoDapp(this.dapp.slug ?? ''),
        this.dapp.getContractsDapp(),
      ]

      await Promise.all(newPromises)

      history.pushState(null, '', this.dapp.slug)

      this.$await.done(`getInfo_${slugOrId}`)
    } catch {
      await this.$router.push('/')
    }
  }
}
